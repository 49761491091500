import React, { useContext, useEffect } from 'react';
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import gradient from "../images/GradientOverlay.png";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GlobalContext } from "../providers/GlobalProvider";

import "../css/about.css";

export const query = graphql`
  {
    hero: allContentfulComponent(filter: { slug: { eq: "about-hero" } }) {
      nodes {
        slug
        headline
        decorator
      }
    }
    headshots: allContentfulMedia(filter: {section: {eq: "headshots"}}, sort: {fields: order}) {
      nodes {
        slug
        media {
          gatsbyImageData
        }
        multiMedia {
          gatsbyImageData
        }
        title
        description
      }
    }
  }
`;

export default function About({ data }) {

  const { setPageScrolled, setPageScrollUp } = useContext(GlobalContext);

  useEffect(() => {
    setPageScrolled(false);
    setPageScrollUp(false);
  }, []);

  return (
    <Layout bgColor='#f8fafb'>
      <div className="about-container">
        {data.hero.nodes.map((info) => (
            <div key={info.slug} className="flex-col">
              <span className="decorator">{info.decorator}</span>
              <div className="headline dark mb-35">{info.headline}</div>
            </div>
        ))}
        <div className="headshot-grid">
          {data.headshots.nodes.map((headshot) => (
            <div key={headshot.slug} className={`relative headshots ${headshot.slug}`}>
              {headshot.title === "Open Rolls" ? (
                <Link
                  to="/contact/"
                  key={headshot.slug}
                  id={headshot.slug}
                  state={{ formType: "career" }}>
                   <GatsbyImage image={getImage(headshot.media.gatsbyImageData)} alt={headshot.title} loading="lazy"/>
                </Link>
              ) : (
                <div>
                   <GatsbyImage image={getImage(headshot.multiMedia[0].gatsbyImageData)}  alt={headshot.title} className="mobile-headshot" loading="lazy"/>
                   <GatsbyImage image={getImage(headshot.media.gatsbyImageData)}  alt={headshot.title} className="web-headshot" loading="lazy"/>
                </div>
              )}
              {headshot.title !== "Open Rolls" ? (
                <div className="hover-gradient">
                  <div className="about-hover">
                    <span className="about-name">{headshot.title}</span>
                    <span className="about-role">{headshot.description}</span>
                  </div>
                  <img
                    src={gradient}
                    className="gradient-img"
                    alt="gradient-img"
                  />
                </div>
              ) : (" ")}
            </div>
          ))}
        </div>
        <div className="spacer-100"></div>
      </div>
    </Layout>
  );
}
